  .title {
    font-family: 'Aspira SemiBold';
    font-size: 32px;
    letter-spacing: -2px;
  }

  .text {
    font-family: 'Aspira Thin';
    font-size: 32px;
}
  