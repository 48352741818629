/* parallax slide up start */
.animationTextContainer {
	position: relative;
	display: flex;
	justify-content: center;
    /* height: 7em */
    height: 3em;
  }
  .wrapper {
	position: absolute;
	/* height: 7rem; */
	height: 3rem;
	overflow-y: hidden;
  }
  .fromBasics{
	animation: slide-up 2.5s infinite;
    animation-delay: 1s;
    transition: ease-in;
	font-size: 3rem !important;
	opacity: 0;
  }
  .second {
	/* position: relative; */
	animation: slide-up-2 2.5s infinite;
    /* animation-delay: 5s; */
    animation-delay: 3s;
    transition: ease-in;
	opacity: 0;
	font-size: 3rem !important;
  }
  
  @media (max-width: 500px) {
	h1, .fromBasics, .second {
		font-size: 2rem !important;
	}
  }


  /* second animation variant */

  @keyframes slide-up {
	0% {
		transform: translateY(-4rem);
		opacity: 0;
	  }
	32% {
	  transform: translateY(-4rem);
	  opacity: 0;
	}
	50% {
	  transform: translateY(0rem);
	  opacity: 1;
	}
	72% {
		transform: translateY(0rem);
		opacity: 1;
	}
	100% {
		transform: translateY(-4rem);
	  	opacity: 0; 
	}
  }
  @keyframes slide-up-2 {
	0% {
		transform: translateY(3rem);
		opacity: 0;
	  }
	20% {
	  transform: translateY(0rem);
	  opacity: 1;
	}
	38% {
		transform: translateY(0rem);
		opacity: 1;
	  }
	
	68% {
	  transform: translateY(4rem);
	  opacity: 0; 
	}
	100% {
		transform: translateY(4rem);
	  	opacity: 0; 
	}
  }
  

  /* parallax slide up end */