.logoContainer {
    width: 80px;
    height: 63px;
}

.logoImg {
    object-fit: contain;
    width: 100%;
}

@media (max-width: 750px) {
    .logoContainer {
        width: 60px;
        height: 43px;
    }
}