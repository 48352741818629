.container {
    display: flex; /* Removed unnecessary height property */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .contentWrapper {
    /* background-color:#4e2da4; */
    background: linear-gradient(90deg, rgba(177, 217, 243, 1) 0%, rgba(255, 210, 220, 1) 100%);
    padding: 12.5em 26em;
    height: 78%; 
    width: 100%;
    position: absolute;
    z-index: -1;
  }
  
  .fontStyles {
    font-size: 3rem !important;
  }

  @media (max-width: 600px) {
    .contentWrapper {
      padding: 10em 8em;
    }
    .fontStyles {
      font-size: 2rem !important;
    }
  
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    .contentWrapper {
      padding: 10.5em 20em;
    }
  
  }
  