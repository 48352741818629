.container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 20;
}

.motionContainer {
	background-color: #ffffff;
	color: #000000;
	width: 100%;
	height: 100%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.drawerContainer {
	width: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	padding: 0.5rem 1rem;
	height: inherit;
	justify-content: center;
}

.backdropContainer {
	background-color: rgba(75, 85, 99, 0.3);
	backdrop-filter: blur(4px);
	position: fixed;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 10;
}

.itemContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-block: 1rem;
}
