* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

html,
body,
#root {
	height: 100%;
	margin: 0;
}

html {
	font-size: min(1.04166vw, 2.08332vh);
}

@media (max-width: 1440px) {
	html {
		font-size: 15px;
	}
}

@media (max-width: 1024px) {
	html {
		font-size: 1.953125vw;
	}
}

@media (max-width: 599px) {
	html {
		font-size: 5.33333vw;
	}
}

body,
html {
	/* -ms-overflow-style: none; */
	/* -webkit-overflow-scrolling: touch; */
	background-color: #fcfcfc;
	height: auto;
	/* overscroll-behavior-y: none; */
	/* scrollbar-width: none; */
	text-rendering: geometricPrecision;
	width: 100%;
	font-family: 'Aspira';
}

@font-face {
	font-display: block;
	font-family: RationalDisplay;
	font-style: normal;
	font-weight: 900;
	src: local('RationalDisplay Black'), url(../src/assets/font/RationalDisplay-Black.c9992.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-Black.5ce21.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-Black.12724.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay;
	font-style: normal;
	font-weight: 600;
	src: local('RationalDisplay Book'), url(../src/assets/font/RationalDisplay-Book.07d65.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-Book.10c6c.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-Book.bb1b1.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay ExtraBold;
	font-style: normal;
	font-weight: 700;
	src: local('RationalDisplay ExtraBold'), url(../src/assets/font/RationalDisplay-ExtraBold.64258.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-ExtraBold.a8d00.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-ExtraBold.6ee6d.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay ExtraLight;
	font-style: normal;
	font-weight: 300;
	src: local('RationalDisplay ExtraLight'),
		url(../src/assets/font/RationalDisplay-ExtraLight.c72e8.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-ExtraLight.17d27.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-ExtraLight.b0a12.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay;
	font-style: normal;
	font-weight: 300;
	src: local('RationalDisplay Light'), url(../src/assets/font/RationalDisplay-Light.9ac30.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-Light.832c3.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-Light.d7458.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay;
	font-style: normal;
	font-weight: 500;
	src: local('RationalDisplay Medium'), url(../src/assets/font/RationalDisplay-Medium.64a21.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-Medium.d0fd6.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-Medium.0a2cc.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay;
	font-style: normal;
	font-weight: 500;
	src: local('RationalDisplay Medium'), url(../src/assets/font/RationalDisplay-Book.07d65.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-Medium.d0fd6.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-Medium.0a2cc.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: RationalDisplay SemiBold;
	font-style: normal;
	font-weight: 600;
	src: local('RationalDisplay SemiBold'), url(../src/assets/font/RationalDisplay-SemiBold.5dd6e.woff2) format('woff2'),
		url(../src/assets/font/RationalDisplay-SemiBold.4c0e3.woff) format('woff'),
		url(../src/assets/font/RationalDisplay-SemiBold.fcda6.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: Aspira Thin;
	font-style: normal;
	font-weight: 300;
	src: local('Aspira Thin'), url(../src/assets/fontPrimary/Aspira-Light.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-Light.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Light.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: 'Aspira';
	font-style: normal;
	font-weight: 400;
	src: local('Aspira Regular'), url(../src/assets/fontPrimary/Aspira-Regular.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-Regular.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Regular.ttf) format('truetype');
}
@font-face {
	font-display: block;
	font-family: 'Aspira Demi';
	font-style: normal;
	font-weight: 500;
	src: local('Aspira Demi'), url(../src/assets/fontPrimary/Aspira-Demi.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-Demi.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Demi.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: Aspira Semibold;
	font-style: normal;
	font-weight: 600;
	src: local('Aspira Semibold'), url(../src/assets/fontPrimary/Aspira-Demi.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-Demi.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Demi.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: Aspira Extrabold;
	font-style: normal;
	font-weight: 700;
	src: local('Aspira Semibold'), url(../src/assets/fontPrimary/Aspira-Heavy.woff2) format('woff2'),
		/* url(../src/assets/fontPrimary/Aspira-Demi.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Demi.ttf) format('truetype'); */
}

@font-face {
	font-display: block;
	font-family: Aspira Bold;
	font-style: normal;
	font-weight: 800;
	src: local('Aspira Bold'), url(../src/assets/fontPrimary/Aspira-Bold.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-Bold.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Bold.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: Aspira Black;
	font-style: normal;
	font-weight: 800;
	src: local('Aspira Black'), url(../src/assets/fontPrimary/Aspira-Black.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-Black.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-Black.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: Aspira Medium Italic;
	font-style: normal;
	font-weight: 500;
	src: local('Aspira Black'), url(../src/assets/fontPrimary/Aspira-MediumIt.woff2) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-MediumIt.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-MediumIt.ttf) format('truetype');
}

@font-face {
	font-display: block;
	font-family: Aspira Regular Italic;
	font-style: normal;
	src: local('Aspira Black'), url(../src/assets/fontPrimary/Aspira-It.woff) format('woff2'),
		url(../src/assets/fontPrimary/Aspira-It.woff) format('woff'),
		url(../src/assets/fontPrimary/Aspira-It.ttf) format('truetype');
}


.swiper {
	width: 100%;
	height: 100vh !important;
}
.swiper-slide {
	height: 100%;
	overflow: hidden;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.swiper-container-vertical {
	height: 100vh;
	overflow-x: hidden;
}

.is-aspira-400 {
	font-family:  Aspira ;
	font-weight: 400 !important;
}
.is-aspira-500 {
	font-family:  Aspira Demi ;
	font-weight: 500 !important;
}

.is-aspira-400 {
	font-family:  Aspira ;
	font-weight: 400 !important;
}

.is-aspira-600 {
	font-family:  Aspira Semibold !important;
	font-weight: 600 !important;
}
.is-aspira-700 {
	font-family:  Aspira Extrabold !important;
	font-weight: 700 !important;
}

.is-aspira-800 {
	font-family:  Aspira Bold;
	font-weight: 800 !important;
}

.is-aspira-500-italic {
	font-family: Aspira Medium Italic;
}

.is-aspira-reguler-italic {
	font-family: Aspira Regular Italic;
}

.LoadinglogoContainer {
    width: 160px;
    height: 93px;
}

.logoImg {
    object-fit: contain;
    width: 100%;
}

@media (max-width: 750px) {
    .logoContainer {
        width: 80px;
        height: 43px;
    }
}


.switch {
	width: 180px;
	height: 60px;
	background-color: rgba(255, 255, 255, 0.4);
	display: flex;
	justify-content: flex-start;
	border-radius: 50px;
	padding: 10px;
	cursor: pointer;
	transform: translateX("-50%");
	font-size: 14px;
  }
  
  .switch[data-isOn="true"] {
	justify-content: flex-end;
  }
  
  .handle {
	width: 90px;
	height: 40px;
	background-color: white;
	border-radius: 40px;
  }

  .initialAnimationWrapper {
	position: fixed;
	top: 0;right: 0;bottom: 0;left: 0;
	background-color: #fcfcfc;
	z-index: 5;
	display: flex;
    flex: 1;
    justify-content: center;
	flex-direction: column;
    align-items: center;
    height: 100%;
	overflow-y: hidden;
  }

  .graduateText {
    font-family: RationalDisplay SemiBold;
    font-weight: 600;
    font-size: 18px;
    line-height: 23.37px;
    letter-spacing: 1.2em;
    text-transform: uppercase;
  }
  @media (max-width: 599px) {
	.graduateText {
		font-size: 13px;
		letter-spacing: .7em;
	}
}

  /*===================*/
/* BOUNCE OUT
/*===================*/

.bounce-out-on-hover {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0) !important;
	transform: perspective(1px) translateZ(0) !important;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0) !important;
	-webkit-transition-duration: 0.3s !important;
	transition-duration: 0.3s !important;
  }
  .bounce-out-on-hover:hover, .bounce-out-on-hover:focus, .bounce-out-on-hover:active {
	-webkit-transform: scale(1.10) !important;
	transform: scale(1.10) !important;
	-webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36) !important;
	transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36) !important;
  }

    /*===================*/
  /* Typing effect 
    /*===================*/

	.typewriter{
		position: relative;
		overflow: hidden; /* Ensures the content is not revealed until the animation */
		border-right: .15em solid rgba(199, 116, 255, 1);
		white-space: nowrap; /* Keeps the content on a single line */
		margin: 0 auto; /* Gives that scrolling effect as the typing happens */
		/* letter-spacing: .15em; */
		animation: 
		  /* typing 3.5s steps(40, end) forwards, */
		  typing 3.5s steps(30, end) forwards,
		  blink-caret .75s step-end;
		  /* animation-fill-mode: forwards; */
	  }
@keyframes typing {
	0% {
		width: 0;
	  }
	  99.99% {
		width: 100%;
		border-color: rgba(199, 116, 255, 1); /* Keep the current border color */
	  }
	  100% {
		width: 100%;
		border-color: transparent; /* Change border color to transparent */
	  }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
	
	50% { border-color: transparent; }
  }
  .underline {
	position: absolute; /* Make it absolute to position over text */
	/* top: 100%;  */
	right: 0;
	bottom:0;
	border: 1px solid rgba(199, 116, 255, 1);
	
	/* Other styles for the line (border, width) */
	animation: underline-grow 1.5s 3.8s ease-in-out;
	
  }
  
  @keyframes underline-grow {
	0% {
	  transform: translateX(100%);
	  width: 0;
	}
	50% {
	  transform: translateX(0);
	  width: 100%;
	}
	100% {
		transform: translateX(-100%);
		width: 0%;
		opacity: 0;
		border: none !important;
	}
  }